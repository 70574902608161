import useSWR from "swr";
import { useFlags } from "flagsmith/react";
import {
  SplatCheckoutStep,
  SplatCheckoutStepsResponse,
} from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { getWithAuth } from "utils/dataUtils";
import { FeatureFlags } from "fixtures/features";

type CheckoutTask = {
  urlSlug?: string;
  label: string;
  taskViewTitle?: string;
  icon: string;
  id: string;
  isComplete: boolean;
  isUnlocked: boolean;
  isInProgress: boolean;
  order: number;
  shouldHideHeaderOnLoading: boolean;
};

const useCheckoutStepsData = (
  prospectId: string,
  proposalId: string,
  authKey: string
) => {
  const flags = useFlags([FeatureFlags.IL_IPA_EMAIL_ENABLED]);
  const ilIpaEnabledUser = flags[FeatureFlags.IL_IPA_EMAIL_ENABLED]?.enabled;
  const CHECKOUT_STEPS_URL = `/prospect/${prospectId}/checkoutSteps?proposalId=${proposalId}`;

  const { data, error, isLoading, mutate, isValidating } = useSWR(
    [prospectId && proposalId ? CHECKOUT_STEPS_URL : undefined, authKey],
    getWithAuth<SplatCheckoutStepsResponse>
  );

  // NOTE: We need to manually remove ipaRebate odi if IL IPA flag is false (user is affiliate)
  let updatedSteps = data?.steps;
  if (data?.steps && !ilIpaEnabledUser) {
    const readAndSignDocsStep = data?.steps.find(
      (step) => step.name === "readAndSignDocs"
    );
    const ipaRebateOdi = readAndSignDocsStep?.odis?.find(
      (odi) => odi.odiName === "ipaRebate"
    );

    if (readAndSignDocsStep && ipaRebateOdi) {
      const updatedOdis = readAndSignDocsStep?.odis?.filter(
        (odi) => odi.odiName !== "ipaRebate"
      );
      const updatedReadAndSignDocsStep: SplatCheckoutStep = {
        ...readAndSignDocsStep,
        odis: updatedOdis,
      };

      updatedSteps = data.steps.map((step) => {
        if (step.name === "readAndSignDocs") {
          return updatedReadAndSignDocsStep;
        }
        return step;
      });
    }
  }

  return {
    steps: updatedSteps,
    opportunityId: data?.opportunityId,
    stepsError: error,
    isStepsLoading: isLoading || isValidating,
    isRefetchingSteps: isValidating,
    canSignInPerson: data?.signInPerson,
    isHqsRequired: data?.isHqsRequired,
    splatRole: data?.splatRole,
    refetch: mutate,
  } as const;
};

export { useCheckoutStepsData };
export type { CheckoutTask };
