import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import qs from "qs";
import { ReviewOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { SplatRole } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { useAppSelector } from "store";
import { CheckoutTask, useCheckoutStepsData } from "checkout/api/checkoutSteps";
import { mapCheckoutStepsToTasks } from "checkout/util/taskHelpers";
import { useNavigateToCheckout } from "utils/useNavigateHelper";
import { useOffer } from "checkout/hooks/useCheckoutSwr";

interface CheckoutContextType {
  tasks: CheckoutTask[];
  proposalId: string;
  prospectId: string;
  opportunityId: string;
  currentTask: CheckoutTask | undefined;
  currentTaskId: string;
  navigateToNextTask: () => void;
  navigateToTask: (taskToNav: string, queryParams?: object) => void;
  navigateToPrevTask: () => void;
  refreshAndNavigateNext: () => void;
  refreshTasks: () => Promise<any>;
  tasksError: Error | undefined;
  tasksLoading: boolean;
  hasNextTask: boolean;
  isRefetchingTasks: boolean;
  offer?: ReviewOffer;
  isOfferLoading: boolean;
  offerError?: Error;
  financePlan?: string;
  loadingOverride: boolean;
  setLoadingOverride: React.Dispatch<React.SetStateAction<boolean>>;
  titleOverride?: Record<string, string | undefined>;
  handleTitleOverride: (title?: string, taskId?: string) => void;
  isHqsRequired: boolean;
  splatRole: SplatRole;
  showSecondaryWarningBanner: boolean;
  setShowSecondaryWarningBanner: React.Dispatch<React.SetStateAction<boolean>>;
  showUtilityBillWarningBanner: boolean;
  setShowUtilityBillWarningBanner: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const CheckoutContext = createContext<CheckoutContextType>({
  tasks: [],
  tasksError: undefined,
  proposalId: "",
  prospectId: "",
  opportunityId: "",
  currentTask: undefined,
  currentTaskId: "",
  navigateToNextTask: () => {},
  navigateToPrevTask: () => {},
  navigateToTask: () => {},
  refreshTasks: () => Promise.resolve({}),
  refreshAndNavigateNext: () => {},
  tasksLoading: true,
  hasNextTask: true,
  isRefetchingTasks: false,
  offer: undefined,
  isOfferLoading: false,
  offerError: undefined,
  loadingOverride: false,
  setLoadingOverride: () => {},
  titleOverride: undefined,
  handleTitleOverride: () => {},
  isHqsRequired: false,
  splatRole: "Other",
  showSecondaryWarningBanner: false,
  setShowSecondaryWarningBanner: () => {},
  showUtilityBillWarningBanner: false,
  setShowUtilityBillWarningBanner: () => {},
});

function useCheckoutContext(): CheckoutContextType {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error(
      "useCheckoutContext must be used within a CheckoutContextProvider"
    );
  }
  return context;
}

const CheckoutContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [showSecondaryWarningBanner, setShowSecondaryWarningBanner] =
    useState(false);
  const [showUtilityBillWarningBanner, setShowUtilityBillWarningBanner] =
    useState(false);
  const [loadingOverride, setLoadingOverride] = useState(false);
  const [titleOverride, setTitleOverride] =
    useState<CheckoutContextType["titleOverride"]>();
  const [tasks, setTasks] = useState<CheckoutTask[]>([]);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const nav = useNavigate();
  const match = useMatch(
    "/prospect/:prospectId/offer/:offerId/checkout/:taskSlug"
  );
  const { offerId, prospectId } = useParams();
  const navigateToCheckout = useNavigateToCheckout();

  const baseUrl = `/prospect/${prospectId}/offer/${offerId}/checkout/`;

  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const cognitoToken = useAppSelector(
    (state) => state?.auth?.cognitoToken?.token
  );

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const { offer, offerError, isOfferLoading } = useOffer(offerId, cognitoToken);

  const proposalId = offer?.proposalId ?? "";

  const {
    steps,
    stepsError,
    isStepsLoading,
    refetch,
    isRefetchingSteps,
    opportunityId,
    isHqsRequired,
    splatRole,
  } = useCheckoutStepsData(prospectId ?? "", proposalId, authKey);

  useEffect(() => {
    if (steps) {
      // Convert the "Splat checkout steps" into "Checkout Tasks"
      const newTaskList = mapCheckoutStepsToTasks(steps);

      if (newTaskList.length <= 0) {
        throw new Error("CheckoutContext received no steps.");
      }

      // Any time we get a new list of tasks back from the api we need to confirm that the current
      // task is still a valid selection.
      let foundTask: CheckoutTask | undefined;
      if (currentTaskId === "") {
        // We are initializing the nav state because of an initial load, a page reload,
        // or a direct url navigation
        foundTask = newTaskList.find(
          (task) => task.urlSlug === match?.params.taskSlug
        );
      } else {
        foundTask = newTaskList.find((task) => task.id === currentTaskId);
      }

      if (!foundTask) {
        setCurrentTaskId(newTaskList[0].id);
      } else {
        setCurrentTaskId(foundTask.id);
      }

      setTasks(newTaskList);
    }
  }, [currentTaskId, match?.params.taskSlug, steps]);

  const currentTask = useMemo(() => {
    return tasks.find((task) => task.id === currentTaskId);
  }, [currentTaskId, tasks]);

  const navigateToTask = useCallback(
    (taskToNav: string, queryParams?: object) => {
      const foundTask = tasks.find((task) => task.id === taskToNav);
      if (!foundTask) {
        console.warn(`Task ${taskToNav} not found, cannot navigate.`);
        return;
      }
      if (!foundTask.isUnlocked) {
        console.warn(`Task ${taskToNav} is locked, cannot navigate.`);
        return;
      }
      if (!match?.params.offerId || !foundTask.urlSlug) {
        console.warn("Cannot nav, missing offer or nav slug");
        return;
      }
      nav(
        `${baseUrl}${foundTask.urlSlug}${
          queryParams ? `?${qs.stringify(queryParams)}` : ""
        }`
      );
      navigateToCheckout(foundTask.urlSlug, match?.params.offerId);
      setCurrentTaskId(foundTask.id);
    },
    [baseUrl, match?.params.offerId, nav, navigateToCheckout, tasks]
  );

  const refreshAndNavigate = useCallback(async () => {
    await refetch();
    setShouldNavigate(true);
  }, [refetch]);

  const currTaskIndex = tasks.findIndex((task) => task.id === currentTaskId);
  const hasNextTask = currTaskIndex !== tasks.length - 1;

  const navigateNext = useCallback(() => {
    const currTaskIndex = tasks.findIndex((task) => task.id === currentTaskId);
    if (currTaskIndex === tasks.length - 1) {
      console.warn("Cannot navigate to next task, on last task");
      return;
    }
    const nextTask = tasks[currTaskIndex + 1].id;
    navigateToTask(nextTask);
  }, [currentTaskId, navigateToTask, tasks]);

  const handleTitleOverride = useCallback(
    (title: string, stepId?: string) => {
      setTitleOverride((prev) => ({
        ...prev,
        [stepId || currentTaskId]: title,
      }));
    },
    [currentTaskId]
  );

  useEffect(() => {
    if (shouldNavigate) {
      setShouldNavigate(false);
      navigateNext();
    }
  }, [shouldNavigate, nav, navigateNext]);

  const checkoutContextValue = useMemo(() => {
    return {
      tasks: tasks,
      tasksError: stepsError,
      currentTask,
      currentTaskId: currentTaskId,
      tasksLoading: isStepsLoading,
      isRefetchingTasks: isRefetchingSteps,
      isHqsRequired,
      splatRole,
      refreshTasks: refetch,
      hasNextTask: hasNextTask,
      navigateToNextTask: navigateNext,
      navigateToPrevTask: () => {
        const currTaskIndex = tasks.findIndex(
          (task) => task.id === currentTaskId
        );
        if (currTaskIndex === 0) {
          console.warn("Cannot navigate to prev task, on first task");
          return;
        }
        const nextTask = tasks[currTaskIndex - 1].id;
        navigateToTask(nextTask);
      },
      navigateToTask: navigateToTask,
      proposalId,
      prospectId,
      opportunityId,
      offer,
      isOfferLoading,
      offerError,
      financePlan: offer?.financial.financePlan,
      refreshAndNavigateNext: refreshAndNavigate,
      loadingOverride,
      setLoadingOverride,
      titleOverride,
      handleTitleOverride,
      showSecondaryWarningBanner,
      setShowSecondaryWarningBanner,
      showUtilityBillWarningBanner,
      setShowUtilityBillWarningBanner,
    } as CheckoutContextType;
  }, [
    tasks,
    stepsError,
    currentTask,
    currentTaskId,
    isStepsLoading,
    isRefetchingSteps,
    isHqsRequired,
    splatRole,
    refetch,
    hasNextTask,
    navigateNext,
    navigateToTask,
    proposalId,
    prospectId,
    opportunityId,
    offer,
    isOfferLoading,
    offerError,
    refreshAndNavigate,
    loadingOverride,
    titleOverride,
    handleTitleOverride,
    showSecondaryWarningBanner,
    showUtilityBillWarningBanner,
  ]);

  return (
    <CheckoutContext.Provider value={checkoutContextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

export { useCheckoutContext, CheckoutContextProvider };
export type { CheckoutContextType };
