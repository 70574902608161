import { Banner, BannerTypes } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";

const messages = {
  English:
    "Coming soon: Customers must sign up for ACH in order to submit for Sunrun Approval.",
  Spanish:
    "Próximamente: Los clientes deberán registrarse en ACH para poder aplicar a la aprobación de Sunrun.",
};

const ACHWarningBanner = ({
  preferredLanguage,
}: {
  preferredLanguage: keyof typeof messages;
}) => (
  <StyledBanner
    id="ach-warning-banner"
    bannerType={BannerTypes.WarningLight}
    show
  >
    {messages[preferredLanguage]}
  </StyledBanner>
);

const StyledBanner = styled(Banner)`
  margin-bottom: 20px;
  .sr-banner-close {
    margin: auto;
  }
`;

export { ACHWarningBanner };
